<uo-checkout-header></uo-checkout-header>
<!-- spinner -->
<div class="fixed-top clearfix" *ngIf="loading">
  <div class="spinner-wrap fixed-top opacity-50">
    <div class="spinner-border spinner position-fixed" role="status"></div>
  </div>
</div>
<div
  class="mt-35r container-fluid"
  #content
  [ngStyle]="{
    'margin-bottom.px': placeOrderBoxHeight
  }"
>
  <form class="row min-vh-100 position-relative" [formGroup]="placeOrderForm">
    <ng-container *ngIf="feeLoading">
      <div class="fixed-top clearfix d-none d-lg-block">
        <div class="fixed-receipt col-lg-3 z-1099">
          <div
            *ngIf="!loading"
            class="spinner-border spinner position-absolute"
            role="status"
          ></div>
        </div>
      </div>
    </ng-container>
    <!-- left half -->
    <div
      [ngClass]="{ 'opacity-25': feeLoading || loading }"
      class="col-12 col-lg-3 d-none d-lg-block bg-yellow ps-xl-4 pe-xl-4 float-start fixed-receipt"
    >
      <!-- header  -->
      <div class="d-flex mt-2 mt-lg-4">
        <img [src]="checkout?.store?.logo" class="pic rounded border" />
        <!-- <div class="pic"></div> -->
        <div class="d-flex justify-content-center flex-fill flex-column ps-2">
          <div class="text-secondary fw-bolder fs-7 user-select-none">
            ORDER FROM
          </div>
          <div class="fs-5 text-dark fw-bolder user-select-none">
            {{ checkout?.store?.store_name }}
          </div>
          <div class="text-dark fw-bold user-select-none">
            {{ checkout?.store?.phone! }}
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="isCheckout">
        <div class="w-100">
          <div
            class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
          >
            Your items expired!
          </div>
        </div>
      </div>
      <!-- save promotion warining -->
      <div class="row mt-2" *ngFor="let coupon of checkout?.coupons">
        <div class="w-100">
          <div
            class="alert-warning rounded shadow-sm text-center fw-500 p-2 user-select-none"
          >
            Your saved money with {{ coupon.name }} code
          </div>
        </div>
      </div>

      <!-- closure danger  -->
      <div class="row mt-2" *ngIf="checkout?.store?.closure">
        <div class="w-100">
          <div
            class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
          >
            Store closed!
          </div>
        </div>
      </div>
      <!-- minimum warning  -->
      <ng-container *ngIf="order_type?.value == 2">
        <ng-container *ngIf="minimumPickupCompare()">
          <div class="row mt-2">
            <div class="w-100">
              <div
                class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
              >
                Miminum pickup price is ${{ minimumPay[2] }}. Please add more
                item to pay!
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="row mt-2" *ngIf="payment_type?.value == 1">
        <div class="w-100">
          <div
            class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
            *ngIf="card_number?.errors?.['required'] || cardName?.errors?.['required']|| expiration_date?.errors?.['required'] || zipcode?.errors?.['required'] || cvv?.errors?.['required']"
          >
            Credit card information missing!
          </div>
        </div>
      </div>
      <!-- count  -->
      <ng-container>
        <div class="w-100 pt-3 pb-3 border-bottom fw-500">
          <div class="d-inline-flex w-100 pb-2">
            <div class="user-select-none fs-6 fw-500">Subtotal</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.subtotal | number: "1.2-2" }}
            </div>
          </div>
          <div
            class="d-inline-flex w-100 pb-1"
            *ngIf="order_type?.value == '1'"
          >
            <div class="user-select-none fs-6 fw-500">Delivery Fee</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.deliveryFees | number: "1.2-2" }}
            </div>
          </div>
          <div
            class="d-inline-flex w-100 pb-1"
            *ngIf="checkout?.coupons?.length"
          >
            <div class="user-select-none fs-6 fw-500">Coupon</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              $-{{ checkout?.couponsAmount | number: "1.2-2" }}
            </div>
          </div>
          <div class="d-inline-flex w-100 align-items-center">
            <div
              class="user-select-none fs-6 fw-500 d-inline-flex align-items-center"
            >
              Estimated Tax
              <button
                type="button"
                class="btn p-1"
                placement="bottom"
                triggers="mouseenter:mouseleave"
                [ngbPopover]="taxPopContent"
                #tax="ngbPopover"
                (click)="tax.open()"
              >
                <i class="ms-75 bi bi-info-circle"></i>
              </button>
            </div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.tax | number: "1.2-2" }}
            </div>
          </div>
          <div class="d-inline-flex w-100 align-items-center">
            <div
              class="user-select-none fs-6 fw-500 d-inline-flex align-items-center"
            >
              Services Fee
              <button
                type="button"
                class="btn p-1"
                placement="bottom"
                triggers="mouseenter:mouseleave"
                [ngbPopover]="otherFeesPopContent"
                #other="ngbPopover"
                (click)="other.open()"
              >
                <i class="ms-75 bi bi-info-circle"></i>
              </button>
            </div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.fees | number: "1.2-2" }}
            </div>
          </div>
        </div>
        <div class="w-100 pt-3 pb-3 border-bottom fw-500">
          <div class="d-inline-flex w-100">
            <div class="user-select-none fs-6 fw-500">Tip</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              <ng-container
                *ngIf="
                  checkout?.tips && checkout?.tips !== '0.00';
                  else noTipsTemplate
                "
              >
                ${{ checkout?.tips | number: "1.2-2" }}
              </ng-container>
              <ng-template #noTipsTemplate> No tips </ng-template>
            </div>
          </div>
        </div>
        <div class="w-100 pt-3 pb-3 border-bottom fw-500">
          <div class="d-inline-flex w-100">
            <div class="user-select-none fs-6 fw-500">Total</div>
            <div class="flex-fill fs-6 user-select-none text-end">
              ${{ checkout?.total | number: "1.2-2" }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-12 col-lg-3">
      <!-- header  -->
      <div class="d-flex mt-4 d-lg-none">
        <img [src]="checkout?.store?.logo" class="pic rounded border" />
        <!-- <div class="pic"></div> -->
        <div class="d-flex justify-content-center flex-fill flex-column ps-2">
          <div class="text-secondary fw-bolder fs-7 user-select-none">
            ORDER FROM
          </div>
          <div class="fs-5 text-dark fw-bolder user-select-none">
            {{ checkout?.store?.store_name }}
          </div>
          <div class="text-dark fw-bold user-select-none">
            {{ checkout?.store?.phone! }}
          </div>
        </div>
      </div>
    </div>

    <!-- right half -->
    <div class="col-12 col-lg-9 border-start">
      <div class="row mt-lg-4">
        <div class="col-12 col-lg-8 offset-lg-2 fw-500">
          <!-- order_type -->
          <div class="w-100 border-bottom pt-3 pb-3 d-inline-flex">

            <div class="col-3 d-inline-flex align-items-center pe-2">
              <div
                class="col-4 col-lg-3 fs-6 fw-bold d-flex align-items-center text-nowrap user-select-none"
              >
                Order type
              </div>
            </div>

            <div
              class="col-9 d-flex flex-column align-items-end align-items-lg-start"
            >
              <div
                class="btn-group"
                role="group"
                aria-label="Delivery or pickup button group"
              >
                <ng-container *ngIf="orderType?.['2']">
                  <input
                    type="radio"
                    class="btn-check"
                    name="order_type"
                    id="pickupRadioBtn"
                    formControlName="order_type"
                    autocomplete="off"
                    [value]="2"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger text-nowrap fw-500"
                    for="pickupRadioBtn"
                    >Dine in
                    </label
                  ></ng-container
                >
              </div>

            </div>


          </div>

          <div class="w-100 border-bottom pt-3 pb-3 d-inline-flex"  *ngIf="tableName">

            <div class="col-3 d-inline-flex align-items-center pe-2">
              <div
                class="col-4 col-lg-3 fs-6 fw-bold d-flex align-items-center text-nowrap user-select-none"
              >
                Table Name
              </div>
            </div>

            <div
              class="col-9 d-flex flex-column align-items-end align-items-lg-start"
            >
              <div
                class="btn-group"
                role="group"
                aria-label="Delivery or pickup button group"
              >

                {{tableName||""}}
              </div>

            </div>


          </div>
          <!-- note -->
          <div
            class="w-100 border-bottom pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0 pb-2 pb-lg-0"
              >
                Notes
              </div>
            </div>
            <ng-container>
              <div class="col-12 col-lg-9" *ngIf="!loading">
                <div class="input-group">
                  <textarea
                    formControlName="notes"
                    class="form-control"
                    aria-label="notes"
                  ></textarea>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- payment -->
          <div
            class="w-100 border-bottom pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0"
              >
                Payments
              </div>
            </div>
            <div class="col-12 col-lg-9">
              <div
                class="btn-group mt-2 mt-lg-0 w-100"
                role="group"
                aria-label="Payments method button group"
              >
                <ng-container *ngIf="paymentType?.['0']">
                  <input
                    name="payment_type"
                    type="radio"
                    class="btn-check"
                    id="payNone"
                    (change)="payNone($event)"
                    formControlName="payment_type"
                    autocomplete="off"
                    [value]="0"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger text-nowrap fw-500"
                    for="payNone"
                    >None</label
                  ></ng-container
                >
                <ng-container *ngIf="paymentType?.['1']">
                  <input
                    type="radio"
                    class="btn-check"
                    id="payCreditCard"
                    name="payment_type"
                    autocomplete="off"
                    formControlName="payment_type"
                    (change)="payByCreditCard($event)"
                    [value]="1"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger text-nowrap fw-500"
                    for="payCreditCard"
                    >Pay by card</label
                  >
                </ng-container>

                <ng-container *ngIf="paymentType?.['2']">
                  <input
                    name="payment_type"
                    type="radio"
                    class="btn-check"
                    id="payInStore"
                    (change)="payInStore($event)"
                    formControlName="payment_type"
                    autocomplete="off"
                    [value]="2"
                  />
                  <label
                    class="btn btn-sm btn-outline-danger text-nowrap fw-500"
                    for="payInStore"
                    >Pay in store</label
                  ></ng-container
                >
              </div>
              <ng-container
                formGroupName="credit_card"
                *ngIf="payment_type?.value == '1'"
              >
                <div class="input-group input-group-sm has-validation mt-2">
                  <span class="input-group-text">Card number</span>
                  <!-- [class.is-invalid]="
                  tax_name!.touched && tax_name!.invalid
                "
                [class.is-valid]="tax_name!.touched && tax_name!.valid" -->
                  <!-- formControlName="name" -->
                  <input
                    #creditCardNumber
                    type="tel"
                    maxlength="19"
                    pattern="[0-9\s]{13,19}"
                    formControlName="card_number"
                    (keyup)="creditCardNumberSpacing()"
                    autocomplete="cc-number"
                    class="form-control"
                    placeholder="card number"
                    aria-label="card-number"
                    aria-describedby="card number"
                  />
                </div>
                <div class="input-group input-group-sm has-validation mt-2">
                  <span class="input-group-text">Name</span>
                  <input
                    type="text"
                    autocomplete="off"
                    formControlName="name"
                    class="form-control w-50"
                    placeholder="card holder"
                    aria-label="card-holder"
                    aria-describedby="card holder"
                  />
                </div>
                <div class="input-group input-group-sm has-validation mt-2">
                  <span class="input-group-text">Zipcode</span>
                  <input
                    type="tel"
                    autocomplete="off"
                    pattern="\d*"
                    class="form-control"
                    maxlength="5"
                    formControlName="zipcode"
                    placeholder="zipcode"
                    aria-label="zipcode"
                    aria-describedby="zipcode"
                  />
                  <span class="input-group-text">CVV</span>
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    maxlength="4"
                    formControlName="cvv"
                    placeholder="CVV"
                    aria-label="cvv"
                    aria-describedby="cvv"
                  />
                </div>
                <div class="input-group input-group-sm has-validation mt-2">
                  <span class="input-group-text">Expiration date</span>
                  <input
                    #expirationDate
                    type="tel"
                    maxlength="5"
                    pattern="([0-9]{2}[/]?){2}"
                    class="form-control w-50"
                    placeholder="MM/YY"
                    formControlName="expiration_date"
                    (keyup)="expirationDateFormat()"
                    autocomplete="cc-exp"
                    aria-label="expired-date"
                    aria-describedby="expired date"
                  /></div
              ></ng-container>
            </div>
          </div>
          <!-- tip -->
          <!-- {{ payment_type?.value }} -->
          <div
            *ngIf="payment_type?.value"
            class="w-100 border-bottom pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0"
              >
                Tips
              </div>
            </div>
            <div class="col-12 col-lg-9" *ngIf="!loading">
              <div
                class="btn-group w-100 mt-2"
                role="group"
                aria-label="tips button radio"
              >
                <ng-container
                  *ngFor="let tip of checkout?.store?.tips; index as i"
                >
                  <input
                    type="radio"
                    class="btn-check"
                    name="tipsBtnRadio"
                    [id]="'tips-' + tip?.id + '-' + tip!.merchant_id"
                    [value]="tip.id"
                    autocomplete="off"
                    [(ngModel)]="tipsId"
                    [disabled]="feeLoading"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="selectTips($event, tip)"
                  />
                  <label
                    class="btn btn-outline-danger user-select-none fs-7 fw-500"
                    [for]="'tips-' + tip?.id + '-' + tip!.merchant_id"
                    >{{ tip?.value }}%</label
                  >
                </ng-container>
                <!-- value="0"
                [(ngModel)]="tipsAmount"
                [ngModelOptions]="{ standalone: true }" -->
                <input
                  type="radio"
                  class="btn-check"
                  id="customTips"
                  name="tipsBtnRadio"
                  autocomplete="off"
                  [disabled]="feeLoading"
                  [checked]="isCustomTips"
                  (click)="customTips($event)"
                />
                <label
                  class="btn btn-outline-danger user-select-none fs-7 fw-500"
                  for="customTips"
                  >custom</label
                >
              </div>

              <!-- <div class="w-100 mt-2" *ngIf="isCustomTips">
                <div class="input-group">
                  <span class="input-group-text user-select-none">$</span>
                  <input
                    type="text"
                    (keyup)=""
                    class="form-control"
                    placeholder="please input tips"
                    aria-label="tip amount"
                    formControlName="tips"
                    aria-describedby="custom"
                  />
                </div>
              </div> -->
              <ng-container *ngIf="isCustomTips">
                Custom tips: {{ checkout?.tips }}</ng-container
              >
            </div>
          </div>
          <!-- summary -->
          <div
            class="w-100 pt-3 pb-3 d-flex flex-column d-lg-inline-flex flex-lg-row border-bottom"
          >
            <div
              class="col-12 col-lg-3 d-inline-flex d-lg-flex flex-row flex-lg-column"
            >
              <div
                class="fs-6 fw-bold user-select-none flex-fill flex-lg-grow-0 pb-3 pb-lg-0"
              >
                Summary
              </div>
              <ng-container *ngIf="!loading">
                <a
                  class="text-lg-start text-end text-danger user-select-none"
                  (click)="navigateToRestaurant()"
                >
                  Change
                </a></ng-container
              >
            </div>
            <div class="col-12 col-lg-9" *ngIf="!loading">
              <div
                [ngClass]="{
                  'border-bottom mb-3': i < items!.length - 1,
                  'text-danger': item.options.expired
                }"
                class="w-100"
                *ngFor="let item of items; let i = index"
              >
                <div class="w-100 fw-bold" *ngIf="item.options.expired">
                  Item expried!
                </div>
                <div class="w-100 d-inline-flex align-items-center">
                  <div class="fw-bold fs-6 user-select-none flex-fill">
                    {{ item?.name }}
                  </div>
                  <div class="fw-500 text-end overflow-hidden user-select-none">
                    {{ item?.quantity }} x ${{ item?.total?.toFixed(2) }}
                  </div>
                </div>
                <div
                  [ngClass]="{
                    'text-danger': item.options.expired
                  }"
                  class="w-100 d-flex flex-wrap align-items-center options fw-500 text-secondary text-wrap toe mb-3"
                >
                  <div
                    class="user-select-none d-flex flex-wrap text-nowrap toe"
                    *ngFor="
                      let modify of item?.options?.itemModifies;
                      index as i
                    "
                  >
                    <ng-container *ngIf="i > 0">&nbsp;•&nbsp;</ng-container>
                    {{ modify?.name }}
                  </div>

                  <ng-container
                    *ngFor="
                      let section of item?.options?.itemSections;
                      index as sectionIndex
                    "
                  >
                    <ng-container *ngIf="sectionIndex > 0">
                      &nbsp;•&nbsp;</ng-container
                    >
                    <ng-container *ngIf="section?.sectionItems">
                      <ng-container *ngIf="item?.options?.itemModifies?.length">
                        &nbsp;•&nbsp;
                      </ng-container>
                      <div
                        class="d-flex flex-wrap text-nowrap toe"
                        *ngFor="
                          let sectionItem of section?.sectionItems;
                          index as i
                        "
                      >
                        <ng-container *ngIf="i > 0">&nbsp;•&nbsp;</ng-container>
                        {{ sectionItem?.item?.name }}&nbsp;x&nbsp;{{
                          sectionItem?.quantity
                        }}
                      </div></ng-container
                    >
                    <ng-container *ngIf="section?.sectionModifiers">
                      <ng-container *ngIf="section?.sectionItems"
                        >&nbsp;•&nbsp;</ng-container
                      >
                      <ng-container
                        *ngIf="
                          item?.options?.itemModifies?.length &&
                          !section?.sectionItems
                        "
                      >
                        &nbsp;•&nbsp;</ng-container
                      >
                      <div
                        class="d-flex flex-wrap text-nowrap toe"
                        *ngFor="
                          let sectionModify of section?.sectionModifiers;
                          index as i
                        "
                      >
                        <ng-container *ngIf="i > 0">&nbsp;•&nbsp;</ng-container>
                        {{ sectionModify?.modify?.name }}&nbsp;x&nbsp;{{
                          sectionModify?.quantity
                        }}
                      </div>
                    </ng-container>
                  </ng-container>
                  <div
                    class="user-select-none d-inline-flex text-nowrap toe"
                    *ngIf="(item?.options)!.itemVariant!"
                  >
                    &nbsp;•&nbsp; ({{
                      (item?.options)!.itemVariant[0]?.name
                    }}
                    ${{ (item?.options)!.itemVariant[0]?.price }})
                  </div>
                  <div
                    class="user-select-none text-nowrap toe"
                    *ngIf="item?.options?.itemInstructions"
                  >
                    {{ item?.options?.itemInstructions }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- count  -->
          <div
            class="d-lg-none mb-2"
            [ngClass]="{ 'opacity-25': feeLoading || loading }"
          >
            <ng-container *ngIf="feeLoading">
              <div class="clearfix d-lg-none">
                <div class="fixed-receipt col-12 z-1099">
                  <div
                    *ngIf="!loading"
                    class="spinner-border spinner position-absolute"
                    role="status"
                  ></div>
                </div>
              </div>
            </ng-container>
            <div class="w-100 pt-3 pb-3 border-bottom fw-500">
              <div class="d-inline-flex w-100 pb-1">
                <div class="user-select-none fs-6 fw-500">Subtotal</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.subtotal | number: "1.2-2" }}
                </div>
              </div>
              <div
                class="d-inline-flex w-100 pb-1"
                *ngIf="order_type?.value == '1'"
              >
                <div class="user-select-none fs-6 fw-500">Delivery Fee</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.deliveryFees | number: "1.2-2" }}
                </div>
              </div>
              <div
                class="d-inline-flex w-100 pb-1"
                *ngIf="checkout?.coupons?.length"
              >
                <div class="user-select-none fs-6 fw-500">Coupon</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  $-{{ checkout?.couponsAmount | number: "1.2-2" }}
                </div>
              </div>
              <div class="d-inline-flex w-100 align-items-center">
                <div
                  class="user-select-none fs-6 fw-500 d-inline-flex align-items-center"
                >
                  Estimated Tax
                  <button
                    type="button"
                    class="btn p-1"
                    placement="bottom"
                    triggers="mouseenter:mouseleave"
                    [ngbPopover]="taxPopContent"
                    #tax="ngbPopover"
                    (click)="tax.open()"
                  >
                    <i class="ms-75 bi bi-info-circle"></i>
                  </button>
                </div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.tax | number: "1.2-2" }}
                </div>
              </div>
              <div class="d-inline-flex w-100 align-items-center">
                <div
                  class="user-select-none fs-6 fw-500 d-inline-flex align-items-center"
                >
                  Services Fee
                  <button
                    type="button"
                    class="btn p-1"
                    placement="bottom"
                    triggers="mouseenter:mouseleave"
                    [ngbPopover]="otherFeesPopContent"
                    #other="ngbPopover"
                    (click)="other.open()"
                  >
                    <i class="ms-75 bi bi-info-circle"></i>
                  </button>
                </div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.fees | number: "1.2-2" }}
                </div>
              </div>
            </div>
            <div class="w-100 pt-3 pb-3 border-bottom fw-500">
              <div class="d-inline-flex w-100">
                <div class="user-select-none fs-6 fw-500">Tip</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  <ng-container
                    *ngIf="
                      checkout?.tips && checkout?.tips !== '0.00';
                      else noTipsTemplate
                    "
                  >
                    ${{ checkout?.tips | number: "1.2-2" }}
                  </ng-container>
                  <ng-template #noTipsTemplate> No tips </ng-template>
                </div>
              </div>
            </div>
            <div class="w-100 pt-3 pb-3 fw-bold">
              <div class="d-inline-flex w-100">
                <div class="user-select-none fs-6">Total</div>
                <div class="flex-fill fs-6 user-select-none text-end">
                  ${{ checkout?.total | number: "1.2-2" }}
                </div>
              </div>
            </div>
          </div>
          <!-- place order -->
          <div
            class="d-none d-lg-flex w-100 pt-4 pb-4 align-items-center justify-content-center"
          >
            <button
              class="col-12 col-lg-6 btn btn-danger shadow-sm fs-6"
              (click)="onPlaceOrder()"
              [disabled]="
                loading ||
                !placeOrderForm.valid ||
                feeLoading ||
                isCheckout ||
                checkout?.store?.closure
              "
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
    </div>


  </form>
</div>
<div
  class="flex-column fixed-bottom container-fluid bg-light d-flex d-lg-none border-top pt-3 pb-3"
  #banners
>
  <div class="w-100 pb-2 d-lg-none" *ngIf="isCheckout">
    <div
      class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
    >
      Some items in the shopping cart expired!
    </div>
  </div>
  <div class="w-100 pb-2 d-lg-none" *ngFor="let coupon of checkout?.coupons">
    <div
      class="alert-warning rounded shadow-sm text-center fw-500 p-2 user-select-none"
    >
      Your saved money with {{ coupon.name }} code
    </div>
  </div>

  <div class="w-100 pb-2 d-lg-none" *ngIf="checkout?.store?.closure">
    <div
      class="alert-danger rounded shadow-sm text-center fw-500 p-2 user-select-none"
    >
      Store closed!
    </div>
  </div>

  <div class="w-100 d-flex align-items-center justify-content-center pt-2 pb-2">
    <button
      class="col-12 col-lg-6 btn btn-danger shadow-sm fs-6"
      (click)="onPlaceOrder()"
      [disabled]="
        loading ||
        !placeOrderForm.valid ||
        feeLoading ||
        isCheckout ||
        checkout?.store?.closure
      "
    >
      Place Order
    </button>
  </div>
</div>
<ng-template #taxPopContent>
  <div class="d-flex flex-column">
    <ng-container *ngFor="let tax of checkout?.store?.tax">
      <div class="d-inline-flex fw-500">
        <div class="text-nowrap">{{ tax.name }}:</div>
        <div class="ms-2">{{ tax.tax | number: "1.2-2" }}%</div>
      </div>
    </ng-container>
  </div>
</ng-template>
<ng-template #otherFeesPopContent>
  <div class="d-flex flex-column"></div>
</ng-template>

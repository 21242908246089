import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';
import { UpdateService } from './services/service-workers/update.service';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'uo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'uOrder';
  private touchStartSubscription!: Subscription;
  private touchEndSubscription!: Subscription;

  constructor(private updateServe: UpdateService) {
    this.updateServe;
    this.googleMap();
    this.doubleClick();
    let url = this.urlParamsToJson(location.href);
    if (!url["table"]) {
      url["table"]=sessionStorage.getItem("table")||"";
    }
    sessionStorage.setItem("table", url["table"]||"");


  }
  urlParamsToJson(url: string): Record<string, string> {
    const queryString = url.split('?')[1]; // 提取 URL 的查询字符串部分
    if (!queryString) {
      return {}; // 如果没有参数，返回空对象
    }

    return queryString.split('&').reduce((acc, param) => {
      const [key, value] = param.split('=').map(decodeURIComponent);
      acc[key] = value;
      return acc;
    }, {} as Record<string, string>);
  }
  private doubleClick(): void {
    this.touchStartSubscription && this.touchStartSubscription.unsubscribe();
    this.touchEndSubscription && this.touchEndSubscription.unsubscribe();
    let lastTouch = 0;
    this.touchStartSubscription = fromEvent(window, 'touchstart').subscribe(
      (event) => {
        if ((event as TouchEvent).touches.length > 1) {
          event.preventDefault();
        }
      }
    );
    this.touchEndSubscription = fromEvent(window, 'touchend').subscribe(
      (event) => {
        const now = new Date().getTime();
        if (now - lastTouch <= 300) {
          event.preventDefault();
          lastTouch = now;
        }
      }
    );
  }
  googleMap(): void {
    new Loader({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places'],
      version: 'weekly',
    })
      .load()
      .then(() => {
        console.log('🚀 ~ GoogleMapsService ~ google maps api lazy loaded');
      });
  }
}

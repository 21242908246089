import { GoogleMapsModule } from '@angular/google-maps';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { WindowService } from 'src/app/services/local/window.service';
import { storageKeys } from 'src/app/share/configs';
import { Router } from '@angular/router';

@Component({
  selector: 'uo-checkout-header',
  templateUrl: './checkout-header.component.html',
  styleUrls: ['./checkout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutHeaderComponent implements OnInit {
  restaurantId!: string;
  constructor(private winServe: WindowService,private router:Router) {}

  ngOnInit(): void {
    this.restaurantId = this.winServe.getLocalStorage(
      storageKeys.store
    ) as string;
  }
  link() {
    let url = "/restaurant/" + this.restaurantId;



    const queryParams={}
    if (sessionStorage.getItem("table")) queryParams["table"] = sessionStorage.getItem("table");
    //  this.router.navigate(["/restaurant/"+this.restaurantId+"?table="+sessionStorage.getItem("table")])

     console.log(url,queryParams)
    this.router.navigate([url], { queryParams: queryParams });



  }
}
